<template>
  <div class="ytEmbeded">
    <div :id="link" class="ytEmbededFallback">
      <iframe
        :src="playerLink"
        frameborder="0"
        referrerpolicy="no-referrer-when-downgrade"
        :auto_play="`${autoplay}`"
        credentialless
      />
    </div>
  </div>
</template>

<script setup lang="ts">
type Props = {
  link: string
}

const props = defineProps<Props>()

const route = useRoute()

const playerLink = computed(() => {
  return `https://www.youtube.com/embed/${props.link}/?controls=1&autoplay=0&rel=0&playsinline=1`
})

const autoplay = computed(() => {
  if (route.path.includes('/band/dashboard/')) return false

  return true
})
</script>

<style scoped>
.ytEmbeded {
  border: 0px;
  width: 100%;
  height: 100%;
  display: block;
}

.ytEmbededFallback,
.ytEmbededFallback > iframe {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
