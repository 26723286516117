<template>
  <iframe
    class="tw-h-full tw-w-full"
    scrolling="no"
    frameborder="no"
    :src="formattedLink"
    referrerpolicy="no-referrer-when-downgrade"
    credentialless
  />
</template>

<script setup lang="ts">
type Props = {
  link: string
}

const props = defineProps<Props>()

const formattedLink = `https://audiomack.com/embed/${props.link.split('.com/')[1]}?autoplay=1`
</script>
