<script setup lang="ts">
import RatioContainer from '~/components/shared/ratioContainer.vue'
import AudiomackPlayer from '~/components/track/universalPlayer/Audiomack.vue'
import BandCampPlayer from '~/components/track/universalPlayer/BandCamp.vue'
import SoundCloudPlayer from '~/components/track/universalPlayer/SoundCloud.vue'
import YouTubePlayer from '~/components/track/universalPlayer/YouTube.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { getTrackDistantApiData } from '~/helpers/distantApi/getTrackDistantApi'

import { isAudiomackTrackUrl } from '~/utils/url-validation'

import { DistantApiError } from '~/types/distantApi'

import type { RatioBindValues } from '~/components/shared/ratioContainer.vue'
import type { DistantApiResponse, PlayerType } from '~/types/distantApi'

type Props = {
  link: string
  format?: RatioBindValues
}
const { t } = useI18n()
const { link, format = { xAspect: 16, yAspect: 9 } } = defineProps<Props>()
const { getMediaUrl } = useGetMediaUrl()
const { coreFetch } = useProvideCoreFetch()

const distantReturn = ref<DistantApiResponse | null>(null)

const playerType = computed<PlayerType | 'error'>(() => {
  if (distantReturn.value && distantReturn.value.type !== 'sp')
    return distantReturn.value.type
  else return 'error'
})

const trackEmbeddedLink = computed<string | 'error'>(() => {
  if (distantReturn.value === null) return 'error'

  return distantReturn.value.err !== DistantApiError.NO_DATA
    ? distantReturn.value.embeddedLink
    : distantReturn.value.errPlaceholder
})

onMounted(fetchEmbedLink)
watch(
  () => link,
  async () => {
    await fetchEmbedLink()
  },
)

const isAudiomackLink = computed(() => isAudiomackTrackUrl(link))

async function fetchEmbedLink(): Promise<void> {
  if (link?.length) {
    const resp = await getTrackDistantApiData(coreFetch, link)
    distantReturn.value = resp
  }
}
</script>

<template>
  <RatioContainer
    v-bind="format"
    :class="{ '!tw-h-[247px] tw-max-h-[247px] !tw-pt-0': isAudiomackLink }"
  >
    <div class="universalPlayerWrapper">
      <div class="loadingBackground">
        <img
          :src="getMediaUrl('GrooverLogoLoader-Primary.gif')"
          :alt="t('common.loading')"
        />
      </div>
      <div class="player">
        <Transition name="fade" mode="out-in">
          <YouTubePlayer
            v-if="trackEmbeddedLink && playerType === 'yt'"
            key="yt"
            :link="trackEmbeddedLink"
          />
          <SoundCloudPlayer
            v-else-if="trackEmbeddedLink && playerType === 'sc'"
            key="sc"
            :link="trackEmbeddedLink"
          />
          <BandCampPlayer
            v-else-if="trackEmbeddedLink && playerType === 'bc'"
            key="bc"
            :link="trackEmbeddedLink"
            :thumbnail="distantReturn?.thumbnail ?? ''"
          />
          <AudiomackPlayer v-else-if="isAudiomackLink" :link="link" />
        </Transition>
      </div>
    </div>
  </RatioContainer>
</template>

<style lang="scss" scoped>
.universalPlayerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.loadingBackground {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingBackground > img {
  width: 42px;
  height: 42px;
}

.player {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
