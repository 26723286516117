<template>
  <iframe
    class="scEmbeded"
    scrolling="no"
    frameborder="no"
    :auto_play="`${autoplay}`"
    :src="link"
    referrerpolicy="no-referrer-when-downgrade"
    credentialless
  />
</template>

<script setup lang="ts">
type Props = {
  link: string
}

const { link } = defineProps<Props>()
const route = useRoute()

const autoplay = computed(() => {
  if (route.path.includes('/band/dashboard/')) return false

  return true
})
</script>

<style lang="scss" scoped>
.scEmbeded {
  border: 0px;
  width: 100%;
  height: 100%;
  display: block;
}
</style>
